import { useQuery } from "react-query";

import { api } from "../api-client";
import { ME } from "./CACHE_KEYS";

export type MeValue = ReturnType<typeof useMe>;

export const queryMe = async () => {
  const { me } = await api.query(
    {
      me: {
        account_id: true,
        external_id: true,
        populations: true,
      },
    },
    {
      operationName: "Me",
    }
  );

  const { account_id, external_id, populations } = me || {};

  return { account_id, external_id, populations };
};

export const useMe = (shouldActivate?: boolean) => {
  const { data, isLoading, error } = useQuery(ME, queryMe, {
    enabled: shouldActivate === true || shouldActivate === undefined,
    retry: 2,
    retryDelay: 700,
  });

  const me = /not found/.test((error as Error)?.message) ? undefined : data;

  return { isLoading, error, me };
};
