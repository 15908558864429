import { Center } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import { FC } from "react";

import loader from "../../../images/loader-DNA.json";

type BoxProps = {
  boxHeight?: string | number;
  height?: string | number;
  boxWidth?: string | number;
  width?: string | number;
};
export const Spinner: FC<BoxProps> = ({
  boxHeight = "100%",
  boxWidth = "100%",
  width = 500,
  height = 500,
}) => {
  return (
    <Center height={boxHeight} width={boxWidth}>
      <Player autoplay loop src={loader} style={{ height, width }}></Player>
    </Center>
  );
};
