import "./index.css";
import "./i18n/config";

import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "App";
import { featureFlags } from "featureFlags";
import { createBrowserHistory, History } from "history";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "reportWebVitals";
import saveRegistrationContentQueryParam from "utils/saveRegistrationContentQueryParam";

import { API_BASE_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./env";
import { store } from "./redux/store";
import theme from "./ui/theme";

saveRegistrationContentQueryParam();
const history = createBrowserHistory();

featureFlags["enable-sentry"] &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SYNAPTICURE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY ?? "", {
  api_host: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
  ignore_dnt: true,
});

const rootElement = document.getElementById("root") as HTMLElement;

const render = (Application: React.FC<{ history: History }>): void => {
  ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: API_BASE_URL,
          connection: "email",
        }}>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <Application history={history} />
          </ChakraProvider>
        </Provider>
      </Auth0Provider>
    </React.StrictMode>,
    rootElement
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App history={history} />, rootElement);
} else {
  render(App);
}

if (module.hot) {
  module.hot.accept("./App", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
