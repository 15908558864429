import "./App.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Token } from "api-client/Auth0Token";
import { featureFlags } from "featureFlags";
import { History } from "history";
import { FC, Suspense, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "react-router-dom";

import { CUSTOMER_IO_SITE_ID } from "./env";
import AppRoutes from "./routes/router";
import LazyLoader from "./ui/atoms/LazyLoader/LazyLoader";
import { Spinner } from "./ui/atoms/Spinner/Spinner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Head: FC = ({ children }): JSX.Element => {
  const scripts = [];

  // Customer.IO tracking script
  if (CUSTOMER_IO_SITE_ID) {
    scripts.push({
      type: "text/javascript",
      innerHTML: `
        var _cio = _cio || [];
        (function() {
          var a,b,c;a=function(f){return function(){_cio.push([f].
          concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
          "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
          var t = document.createElement('script'),
              s = document.getElementsByTagName('script')[0];
          t.async = true;
          t.id    = 'cio-tracker';
          t.setAttribute('data-site-id', '${CUSTOMER_IO_SITE_ID}');
          t.src = 'https://assets.customer.io/assets/track.js';
          s.parentNode.insertBefore(t, s);
        })();
      `,
    });
  }

  return (
    <>
      <Helmet script={scripts} />
      {children}
    </>
  );
};

const App: FC<{ history: History }> = ({ history }): JSX.Element => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getAccessTokenSilently) {
      const auth0Token = Auth0Token.getInstance();
      auth0Token.setTokenGenerator(getAccessTokenSilently);
      auth0Token.setLogoutFunction(logout);
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, logout]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Head>
      <Suspense fallback={<LazyLoader delay={300} />}>
        <Router history={history}>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            {featureFlags["enable-react-query-dev-tools"] && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </Router>
      </Suspense>
    </Head>
  );
};

export default App;
