import { OAuthError, useAuth0 } from "@auth0/auth0-react";
import { FC, useCallback, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router";

import { useMe } from "../queries/me";
import { Spinner } from "../ui/atoms/Spinner/Spinner";
import { Routes } from ".";

export const ProtectedRoute: FC<RouteProps> = ({
  children,
  component: Comp,
  path,
  ...rest
}) => {
  const { isAuthenticated, logout, getAccessTokenSilently, isLoading } =
    useAuth0();
  const { me, isLoading: isMeLoading } = useMe(isAuthenticated);
  // me query returns an empty account_id for a period, hence the need to check (!me?.account_id && !error)

  const checkAuthorization = useCallback(async () => {
    try {
      await getAccessTokenSilently();
    } catch (e) {
      if ((e as OAuthError).error === "invalid_grant") {
        logout();
      }
    }
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    checkAuthorization();
  }, [path, checkAuthorization]);

  if (!isAuthenticated && !isLoading) {
    logout();
  }

  if (isMeLoading || isLoading) {
    return <Spinner />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && me?.account_id ? (
          Comp ? (
            <Comp {...props} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
