import { extendTheme } from "@chakra-ui/react";

/*
https://www.figma.com/file/PgZ6UTwtk8JB0ZbK5WYohA/SynapDesign?node-id=12%3A15
*/

/* INFO */
const MidnightGreen = "#005766"; // [info]: var(--chakra-colors-syncure-info-MidnightGreen);
const BlueMunsell = "#008BA3"; // [info]: var(--chakra-colors-syncure-info-BlueMunsell);
const PacificBlue = "#00ADCC"; // [info]: var(--chakra-colors-syncure-info-PacificBlue);
const SkyBlueCrayola = "#0ADAFF"; // [info]: var(--chakra-colors-syncure-info-SkyBlueCrayola);
const BlizzardBlue = "#70EAFF"; // [info]: var(--chakra-colors-syncure-info-BlizzardBlue);

/* ERROR */
const RugbyRed = "#A30016"; // [error]: var(--chakra-colors-syncure-error-RugbyRed);
const CadmiumRed = "#E0001E"; // [error]: var(--chakra-colors-syncure-error-CadmiumRed);
const RedPigment = "#FF0022"; // [error]: var(--chakra-colors-syncure-error-RedPigment);
const UltraRed = "#F67788"; // [error]: var(--chakra-colors-syncure-error-UltraRed);
const LightPink = "#F6B2BB"; // [error]: var(--chakra-colors-syncure-error-LightPink);
const LightPink20 = "#F6B2BB20"; // [error]: var(--chakra-colors-syncure-error-LightPink);

/* WARNING */
const Gold = "#A37D00"; // [warning]: var(--chakra-colors-syncure-warning-Gold);
const Goldenrod = "#E0AC00"; // [warning]: var(--chakra-colors-syncure-warning-Goldenrod);
const MikadoYellow = "#FFC300"; // [warning]: var(--chakra-colors-syncure-warning-MikadoYellow);
const Mustard = "#FFD95C"; // [warning]: var(--chakra-colors-syncure-warning-Mustard);
const Champagne = "#FFECAD"; // [warning]: var(--chakra-colors-syncure-warning-Champagne);

/* SUCCESS */
const LincolnGreen = "#2A5B0B"; // [success]: var(--chakra-colors-syncure-success-LincolnGreen);
const SlimyGreen = "#439211"; // [success]: var(--chakra-colors-syncure-success-SlimyGreen);
const KellyGreen = "#58BF18"; // [success]: var(--chakra-colors-syncure-success-KellyGreen);
const GrannySmithApple = "#ADE08D"; // [success]: var(--chakra-colors-syncure-success-GrannySmithApple);
const TeaGreen = "#D4F3C1"; // [success]: var(--chakra-colors-syncure-success-TeaGreen);

/* BLACK */
const Black20 = "rgba(0,0,0, 0.2)"; // [black]: var(--chakra-colors-black-Black20);
const Black42 = "rgba(0,0,0, 0.42)"; // [black]: var(--chakra-colors-black-Black42);
const Black67 = "rgba(0,0,0, 0.67)"; // [black]: var(--chakra-colors-black-Black67);
const Black81 = "rgba(0,0,0, 0.81)"; // [black]: var(--chakra-colors-black-Black81);
const Black87 = "rgba(0,0,0, 0.87)"; // [black]: var(--chakra-colors-black-Black87);
const Black = "rgba(0,0,0)"; // [black]: var(--chakra-colors-black-Black);

/* WHITE */
const White25 = "rgba(255,255,255, 0.25)"; // [white]: var(--chakra-colors-syncure-white-White25);
const White48 = "rgba(255,255,255, 0.48)"; // [white]: var(--chakra-colors-syncure-white-White48);
const White73 = "rgba(255,255,255, 0.73)"; // [white]: var(--chakra-colors-syncure-white-White73);
const White90 = "rgba(255,255,255, 0.90)"; // [white]: var(--chakra-colors-syncure-white-White90);
const White = "rgba(255,255,255)"; // [white]: var(--chakra-colors-syncure-white-White);

/* GRAY */
const RaisinBlack = "#24282F"; // [gray]: var(--chakra-colors-syncure-gray-RaisinBlack);
const Charcoal = "#313B49"; // [gray]: var(--chakra-colors-syncure-gray-Charcoal);
const BlackCoral = "#52637A"; // [gray]: var(--chakra-colors-syncure-gray-BlackCoral);
const Manatee = "#8D99AE"; // [gray]: var(--chakra-colors-syncure-gray-Manatee);
const LightSteelBlue = "#B4C0CF"; // [gray]: var(--chakra-colors-syncure-gray-LightSteelBlue);
const Gainsboro = "#DAE0E7"; // [gray]: var(--chakra-colors-syncure-gray-Gainsboro);
const AliceBlue = "#EDF2F4"; // [gray]: var(--chakra-colors-syncure-gray-AliceBlue);
const Whitish = "#F7F9FA"; // [gray]: var(--chakra-colors-syncure-gray-Whitish);
const Gray2 = "#BDBDBD"; // [gray]: var(--chakra-colors-syncure-gray-Whitish);

/* ORANGE */
const SealBrown = "#4B2406"; // [orange]: var(--chakra-colors-syncure-orange-SealBrown);
const SaddieBrown = "#97480C"; // [orange]: var(--chakra-colors-syncure-orange-SaddieBrown);
const ChocolateWeb = "#D06311"; // [orange]: var(--chakra-colors-syncure-orange-ChocolateWeb);
const PrincetonOrange = "#EE8434"; // [orange]: var(--chakra-colors-syncure-orange-PrincetonOrange);
const MacaroniandCheese = "#F6BB8E"; // [orange]: var(--chakra-colors-syncure-orange-MacaroniandCheese);
const Almond = "#FCE8D9"; // [orange]: var(--chakra-colors-syncure-orange-Almond);

/* RED */
const Rosewood = "#520013"; // [red]: var(--chakra-colors-syncure-red-Rosewood);
const RubyRed = "#A30026"; // [red]: var(--chakra-colors-syncure-red-RubyRed);
const Crimson = "#FF3363"; // [red]: var(--chakra-colors-syncure-red-Crimson);
const TartOrange = "#F55151"; // [red]: var(--chakra-colors-syncure-red-TartOrange);
const LightCoral = "#FC7878"; // [red]: var(--chakra-colors-syncure-red-LightCoral);
const PalePink = "#FFD9D9"; // [red]: var(--chakra-colors-syncure-red-PalePink);

/* BLUE */
const SpaceCadet = "#16224F"; // [blue]: var(--chakra-colors-syncure-blue-SpaceCadet);
const StPatricksBlue = "#0D2375"; // [blue]: var(--chakra-colors-syncure-blue-StPatricksBlue);
const InternationalKleinBlue = "#122DA5"; // [blue]: var(--chakra-colors-syncure-blue-InternationalKleinBlue);
const PersianBlue = "#183CDC"; // [blue]: var(--chakra-colors-syncure-blue-PersianBlue);
const UltramarineBlue = "#4765EB"; // [blue]: var(--chakra-colors-syncure-blue-UltramarineBlue);
const Lavender = "#B6C2F7"; // [blue]: var(--chakra-colors-syncure-blue-Lavender);
const Magnolia = "#EDF0FD"; // [blue]: var(--chakra-colors-syncure-blue-Magnolia);

/* BRAND
  StPatricksBlue - #0D2375
  Crimson - #FF3363
  PrincetonOrange - #EE8434
  AliceBlue - #EDF2F4
  Manatee - #8D99AE
*/

const theme = extendTheme({
  components: {
    Badge: {
      variants: {
        sidebar: {
          fontSize: "9px",
          backgroundColor: Manatee,
          color: "white",
          fontWeight: "normal",
          position: "relative",
        },
      },
    },
  },
  colors: {
    blue: {
      SpaceCadet,
      StPatricksBlue,
      InternationalKleinBlue,
      PersianBlue,
      UltramarineBlue,
      Lavender,
      Magnolia,
    },
    error: {
      LightPink20,
      RugbyRed,
      CadmiumRed,
      RedPigment,
      UltraRed,
      LightPink,
    },
    info: {
      MidnightGreen,
      BlueMunsell,
      PacificBlue,
      SkyBlueCrayola,
      BlizzardBlue,
    },
    warning: {
      Gold,
      Goldenrod,
      MikadoYellow,
      Mustard,
      Champagne,
    },
    success: {
      LincolnGreen,
      SlimyGreen,
      KellyGreen,
      GrannySmithApple,
      TeaGreen,
    },
    black: {
      Black20,
      Black42,
      Black67,
      Black81,
      Black87,
      Black,
    },
    white: {
      White25,
      White48,
      White73,
      White90,
      White,
    },
    gray: {
      RaisinBlack,
      Charcoal,
      BlackCoral,
      Manatee,
      LightSteelBlue,
      Gainsboro,
      AliceBlue,
      Whitish,
      Gray2,
    },
    orange: {
      SealBrown,
      SaddieBrown,
      ChocolateWeb,
      PrincetonOrange,
      MacaroniandCheese,
      Almond,
    },
    red: {
      Rosewood,
      RubyRed,
      Crimson,
      TartOrange,
      LightCoral,
      PalePink,
    },
    brand: {
      StPatricksBlue,
      Crimson,
      PrincetonOrange,
      AliceBlue,
      Manatee,
    },
    syncure: {
      blue: {
        SpaceCadet,
        StPatricksBlue,
        InternationalKleinBlue,
        PersianBlue,
        UltramarineBlue,
        Lavender,
        Magnolia,
        500: PersianBlue,
      },
      error: {
        LightPink20,
        RugbyRed,
        CadmiumRed,
        RedPigment,
        UltraRed,
        LightPink,
      },
      info: {
        MidnightGreen,
        BlueMunsell,
        PacificBlue,
        SkyBlueCrayola,
        BlizzardBlue,
      },
      warning: {
        Gold,
        Goldenrod,
        MikadoYellow,
        Mustard,
        Champagne,
      },
      success: {
        LincolnGreen,
        SlimyGreen,
        KellyGreen,
        GrannySmithApple,
        TeaGreen,
      },
      black: {
        Black20,
        Black42,
        Black67,
        Black81,
        Black,
      },
      white: {
        White25,
        White48,
        White73,
        White90,
        White,
      },
      gray: {
        RaisinBlack,
        Charcoal,
        BlackCoral,
        Manatee,
        LightSteelBlue,
        Gainsboro,
        AliceBlue,
        Whitish,
      },
      orange: {
        SealBrown,
        SaddieBrown,
        ChocolateWeb,
        PrincetonOrange,
        MacaroniandCheese,
        Almond,
      },
      red: {
        Rosewood,
        RubyRed,
        Crimson,
        TartOrange,
        LightCoral,
        PalePink,
      },

      brand: {
        StPatricksBlue,
        Crimson,
        PrincetonOrange,
        AliceBlue,
        Manatee,
      },
    },
  },
  fonts: {
    body: "Work Sans",
    heading: "Work Sans",
  },
  shadows: {
    alert: `
      0px 8px 24px -4px #18274B14,
      0px 6px 12px -6px #18274B1F;
    `,
    inputFocus: `0 0 1px ${UltramarineBlue}`,
  },
});

export default theme;
