import React, { FC, useEffect, useState } from "react";

import { Spinner } from "../Spinner/Spinner";

export interface LazyLoaderProps {
  delay?: number;
}

const LazyLoader: FC<LazyLoaderProps> = ({ delay = 300 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <Spinner /> : null;
};

export default LazyLoader;
