import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

/*
  Collect attribution data from URL.

  This really should be done explicitly during app init rather than when
  loading the module, but the value of configureStore is used elsewhere in
  the app. Would be a fair amount of refactoring to pull from context.
*/
let attribution: RootState["attribution"] = null;
if (window?.location) {
  const params = new URLSearchParams(window.location.search);

  const source = params.get("utm_source") || undefined;
  const medium = params.get("utm_medium") || undefined;
  const campaign = params.get("utm_campaign") || undefined;
  const term = params.get("utm_term") || undefined;
  const content = params.get("utm_content") || undefined;
  const adgroup = params.get("utm_adgroup") || undefined;

  if (source || medium || campaign || term || content || adgroup) {
    attribution = {
      source,
      medium,
      campaign,
      term,
      content,
      adgroup,
    };
  }
}

export const store = configureStore<RootState>({
  preloadedState: {
    attribution,
  },
  reducer: {
    attribution: (state: RootState["attribution"] | undefined) => state || null,
  },
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type RootState = {
  attribution: null | {
    source?: string;
    medium?: string;
    campaign?: string;
    term?: string;
    content?: string;
    adgroup?: string;
  };
};
