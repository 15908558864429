//this is the same code that runs in webflow
export default function saveRegistrationContentQueryParam() {
  function setPersistentCookie(key, value, domain) {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 100);
    document.cookie =
      key +
      "=" +
      value +
      ";expires=" +
      date.toUTCString() +
      ";domain=" +
      domain +
      ";path=/";
  }
  function getTLD() {
    var hostname = window.location.hostname;
    var parts = hostname.split(".");
    if (parts.length >= 2) {
      return parts[parts.length - 2] + "." + parts[parts.length - 1];
    }
    return hostname;
  }
  const REGISTRATION_CONTENT_PARAM_NAME = "_syn_rc";
  const REGISTRATION_CONTENT_COOKIE_NAME = "_syn_rc";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const regContentParam = urlParams.get(REGISTRATION_CONTENT_PARAM_NAME);
  const utmCampaignParam = urlParams.get("utm_campaign");
  if (regContentParam != null) {
    setPersistentCookie(
      REGISTRATION_CONTENT_COOKIE_NAME,
      regContentParam,
      getTLD()
    );
  }
  if (utmCampaignParam === "PD_1") {
    setPersistentCookie(REGISTRATION_CONTENT_COOKIE_NAME, "1", getTLD());
  }
}
